const mainTheme = {
  color: {
    primary: '#2B3B5C',
    secondary: '#FAB041',
    background: '#8FA1BB',
    grey: '#D1D4DD',
    dark: '#1C1C1C',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'Karla, sans-serif',
      secondary: 'Verdana, Arial',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '26px',
      amazing: '30px',
      huge: '36px',
      mega: '40px',
      ultra: '50px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
