import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import Logo from 'img/footerlogo.inline.svg'
import Houses from 'img/houses.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.background};
  color: ${(props) => props.theme.color.light};
`

const HousesWrapper = styled.div`
  position: absolute;
  top: -74px;
`

const Brand = styled(NavLink)`
  & > svg {
    height: 84px;
    width: 150px;
    &:hover {
      opacity: 0.65;
    }
  }
`

const Content = styled(ParseContent)`
  & a {
    font-weight: ${({ theme }) => theme.font.weight.light} !important;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          description
        }
      }
    }
  `)

  return (
    <StyledFooter className="position-relative">
      <div className="container py-5">
        <HousesWrapper>
          <Houses />
        </HousesWrapper>
        <div className="d-flex flex-column flex-sm-row align-items-center">
          <Brand to="/" className="me-5 pe-5">
            <Logo />
          </Brand>
          <div className="">
            <Content content={fields?.footer?.description} />
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
