/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

import { useMedia } from 'use-media'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import FormModal from 'components/flex/Form/FormModal'

const StyledHeader = styled(motion.nav)<{ isSticky: boolean }>`
  background-color: ${(props) => props.theme.color.light};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 95px;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  & > svg {
    @media (min-width: 992px) {
      height: 84px;
      width: 150px;
    }
    @media (max-width: 991px) {
      height: 64px;
      width: 130px;
    }
    &:hover {
      opacity: 0.65;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonTitle = styled.div`
  color: ${(props) => props.theme.color.primary};
  text-transform: uppercase;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  height: 2px;
  width: 70%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            item {
              link {
                title
                url
              }
              ishighlighted
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader
      isSticky={isSticky}
      initial={{ boxShadow: 'none' }}
      animate={
        isSticky
          ? {
              boxShadow: '0px 5px 9px 0px rgba(0, 0, 0, 0.1)',
            }
          : { boxShadow: 'none' }
      }
    >
      <Container className="container">
        <Brand to="/">
          <Logo />
        </Brand>

        <MenuMobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <MenuDesktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 100px 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 22px;
        display: block;
        padding: 10px 0;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButtonCloseTitle = styled.div`
  color: ${(props) => props.theme.color.light};
  text-transform: uppercase;
  margin-top: 3px;
`

interface MenuMobileProps {
  fields: any
  isSticky?: boolean
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonTitle>Menu</NavButtonTitle>
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
            <NavButtonCloseTitle>Sluiten</NavButtonCloseTitle>
          </NavButtonClose>
          <ul className="d-flex flex-column align-items-center">
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  padding-left: 0;
  flex: 1;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
  @media (min-width: 1200px) {
    justify-content: flex-end;
  }
  @media (max-width: 1199px) {
    justify-content: flex-start;
  }
`

interface MenuDesktopProps {
  fields: any
  isSticky?: boolean
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  position: relative;
  display: flex;
  padding-top: 0.85rem;
  padding-bottom: 0.85rem;

  & > a {
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      left: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;

      @media (min-width: 992px) {
        background-color: ${({ theme }) => theme.color.primary};
        bottom: 24px;
      }
      @media (max-width: 991px) {
        background-color: ${({ theme }) => theme.color.light};
        color: ${({ theme }) => theme.color.light};
        bottom: 0;
        &:hover {
          color: ${({ theme }) => theme.color.light} !important;
        }
      }
    }

    &[aria-current] {
      &:before {
        visibility: visible;
        width: 100%;
      }
    }

    &:hover:before {
      visibility: visible;
      width: 100%;
    }
    @media (min-width: 1200px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
    @media (max-width: 1199px) {
      font-size: ${({ theme }) => theme.font.size.small};
    }
    @media (min-width: 992px) {
      color: ${({ theme }) => theme.color.primary};
    }

    @media (max-width: 991px) {
      color: ${({ theme }) => theme.color.light} !important;
    }
  }

  @media (min-width: 1200px) {
    margin-left: 34px;
  }
  @media (max-width: 1199px) {
    margin-left: 26px;
  }
  @media (max-width: 991px) {
    margin-left: 0;
  }
`

const SubmenuWrapper = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 97.5px;
  background-color: ${({ theme }) => theme.color.light};
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [isHover, setHover] = useState<number | null>(null)
  const isDesktop = useMedia({ minWidth: 992 })

  const subMenuAnimations = {
    enter: {
      opacity: 1,
      rotateX: 0,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'block',
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <>
      {fields.header.menuHeader.map(({ item, submenu }: any, index: number) => {
        // --------   TIJDELIJK UITGECOMMENT -> Op aanvraag aanzetten   --------

        // const hovered: boolean = isHover === index

        // if (submenu && isDesktop) {
        //   return (
        //     <div
        //       // eslint-disable-next-line react/no-array-index-key
        //       key={index}
        //       className="position-relative"
        //       onMouseEnter={() => setHover(index)}
        //       onMouseLeave={() => setHover(null)}
        //     >
        //       {/* eslint-disable-next-line react/no-array-index-key */}
        //       <Item key={index}>
        //         <NavLink to={item.link.url} className="py-lg-4">
        //           {item.link.title}
        //         </NavLink>
        //       </Item>
        //       <SubmenuWrapper
        //         initial="exit"
        //         animate={hovered ? 'enter' : 'exit'}
        //         variants={subMenuAnimations}
        //       >
        //         <Submenu items={submenu} />
        //       </SubmenuWrapper>
        //     </div>
        //   )
        // }

        if (item.ishighlighted) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={index}>
              <ButtonPrimary to="/" className="mt-4 mt-lg-0" isCustom>
                <button type="button" onClick={() => setPopupOpen(true)}>
                  {item.link.title}
                </button>
              </ButtonPrimary>
              <FormModal
                isOpen={popupOpen}
                requestClose={() => setPopupOpen(false)}
              />
            </Item>
          )
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            <NavLink to={item.link.url} className="py-lg-4">
              {item.link.title}
            </NavLink>
          </Item>
        )
      })}
    </>
  )
}

const SubmenuItem = styled.li`
  padding: 1.3rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.light};
  }
`

interface SubmenuProps {
  items: Array<any>
}

const Submenu: React.FC<SubmenuProps> = ({ items }) => (
  <>
    {items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <SubmenuItem key={index}>
        <NavLink to={item.link.url} className="py-5">
          {item.link.title}
        </NavLink>
      </SubmenuItem>
    ))}
  </>
)

export default Header
