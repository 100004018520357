/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ButtonSecondaryDark from 'components/elements/Buttons/ButtonSecondaryDark'
import ButtonSecondaryLight from 'components/elements/Buttons/ButtonSecondaryLight'
import FormModal from 'components/flex/Form/FormModal'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.medium};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    & li {
    }
  }

  & p {
    font-weight: ${({ theme }) => theme.font.weight.regular};
    line-height: ${({ theme }) => theme.font.size.large};
    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.primary};
    font-weight: 600;
    &:hover {
      text-decoration: none;
    }
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode
      const [isOpen, setIsOpen] = React.useState<boolean>(false)

      if (name === 'a' && attribs.class === 'button-primary') {
        return (
          <ButtonPrimary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'a' && attribs.class === 'button-secondary-dark') {
        return (
          <ButtonSecondaryDark to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonSecondaryDark>
        )
      }

      if (name === 'a' && attribs.class === 'button-secondary-light') {
        return (
          <ButtonSecondaryLight to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonSecondaryLight>
        )
      }

      if (name === 'a' && attribs.class === 'button-primary-interest') {
        return (
          <>
            <ButtonPrimary
              to={attribs.href}
              target={attribs.target || ''}
              isCustom
            >
              <button type="button" onClick={() => setIsOpen(true)}>
                {domToReact(children, {})}
              </button>
            </ButtonPrimary>
            {isOpen && (
              <FormModal
                isOpen={isOpen}
                requestClose={() => setIsOpen(false)}
              />
            )}
          </>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content?: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
