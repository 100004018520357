import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

export const ButtonSecondaryCSS = css`
  background-color: transparent;
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.color.primary};
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.color.secondary};
    border: 1px solid ${({ theme }) => theme.color.secondary};
  }

  & > a,
  & > button {
    color: ${(props) => props.theme.color.primary} !important;
    font-size: ${({ theme }) => theme.font.size.medium} !important;
    padding: 10px 30px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
    font-weight: ${({ theme }) => theme.font.weight.light} !important;
  }

  @media (max-width: 767px) {
    display: flex;
    text-align: center;
  }
`

const StyledButton = styled.span`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
}

const ButtonSecondaryDark: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonSecondaryDark
