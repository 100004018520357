import React from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Modal from 'react-modal'
import { useMedia } from 'use-media'

// Elements
import { ButtonSecondaryCSS } from 'components/elements/Buttons/ButtonSecondaryLight'

// Components
import FormDuuf from 'components/related/Form'

// Images
import Cancel from 'img/cancel.inline.svg'

const StyledFormDuuf = styled(FormDuuf)`
  position: relative;

  & .form-duuf-group {
    width: 100%;
    margin-bottom: 2rem;
  }

  & .form-duuf-textarea,
  & .form-duuf-input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    border-radius: unset;
    padding: 0.8rem 1rem;
  }

  & .form-duuf-textarea {
    height: 100px;
  }

  & .form-duuf-file-button {
    ${ButtonSecondaryCSS};
  }

  & .form-duuf-file-name {
    margin-left: 0.5rem;
  }

  & .form-duuf-checkbox-label {
    margin-left: 0.5rem;
    margin-bottom: 0;
  }

  & .form-duuf-label-mandatory {
    color: ${({ theme }) => theme.color.secondary};
  }

  & .form-duuf-error {
    color: tomato;
  }

  & .form-duuf-submit {
    ${ButtonSecondaryCSS};

    &-disabled {
      opacity: 0.5;
    }
  }

  & .form-duuf-confirm {
    margin: 0 auto;
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.huge};
    margin-bottom: 3rem;
    @media (min-width: 576px) {
      width: 75%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  & .form-duuf-something-wrong {
    color: red;
  }

  & .form-duuf-loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.background} !important;
  }

  @media (max-width: 575px) {
    overflow-y: scroll !important;
  }
`

const StyledModal = styled(Modal)`
  background: ${({ theme }) => theme.color.background};
  @media (min-width: 992px) {
    padding: 3rem;
    width: 50%;
    max-width: 800px;
  }
  @media (max-width: 991px) {
    padding: 1.5rem;
    width: 95%;
  }

  & p {
    color: ${({ theme }) => theme.color.light};
  }
`

const CancelWrapper = styled.div`
  right: 16px;
  top: 12px;
  &:hover {
    cursor: pointer;
    & svg {
      opacity: 0.5;
    }
  }

  & svg {
    width: 25px;
    height: 25px;
  }
`

interface FormModalProps {
  isOpen: boolean
  requestClose: Function
}

const FormModal: React.FC<FormModalProps> = ({ isOpen, requestClose }) => {
  const shortHeightDevice = useMedia({ maxHeight: 750 })

  const customStyles = {
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      right: 'auto',
      height: shortHeightDevice ? '100%' : 'auto',
      overflowY: shortHeightDevice ? 'scroll' : 'auto',
      zIndex: 999,
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={requestClose}
      style={customStyles}
    >
      <CancelWrapper
        className="position-absolute"
        onClick={() => requestClose()}
      >
        <Cancel />
      </CancelWrapper>
      <h2 className="mb-3">Belangstelling</h2>
      <p className="mb-4">
        Heeft u belangstelling voor een ontwikkeling? Neem dan contact met ons
        op via onderstaand formulier.
      </p>
      <StyledFormDuuf id={2} />
    </StyledModal>
  )
}

export default FormModal
